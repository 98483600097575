import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout3 from '../components/layout';
import Styles from "./chirisogo.module.css";
import Citytitle from "../components/citysetting/citytitle4"





const bunya = props => (
<div className={Styles.mokuji} style={{display:"flex"}}>
<div style={{width:"10%"}}>
  <div>
    <img className={Styles.mokujiimg} src={props.src} />
  </div>        
</div>        
<div className={Styles.mokujiright}>
  <div>
    <a href="#basic" className={Styles.mokujiname}><h2>{props.bunya}</h2></a>
  </div>

  <div className={Styles.pagelinkdiv}>
      {data.allMarkdownRemark.edges.filter(x => x.node.frontmatter.tags[1] === 'さまざまな交通網の発達').map(({ node }, index) => (
        <div className={Styles.pagelink} key={index}>
          <Link  className={Styles.pagelink} to={"/"+node.frontmatter.no+"/erabu"}>{node.frontmatter.no}　{node.frontmatter.title}
            </Link>
        </div>
      ))}
  </div>
</div>
</div>
)



const HomePage = (props: any) => {
  const { data } = props;

  return (
    <Layout3>
        <div style={{margin: "30px"}}>
        {/* <h1>地理総合</h1>
        <p>「地理総合」では、地図や地理情報システムなどを用いて、その情報を収集、読み取り、まとめる基礎的・基本的な技能を身に付ける学習が重視されていますが、それで完結するものではなく、これを活用して事象を説明したり、自分の解釈を加えて論述したり、討論したりする活動が重視されています。</p>
        <br/>

        <h2>まず初めに、自分の都市を設定しましょう</h2>
        <Citytitle />
        <br/>

        <h2>SAASの特徴</h2>
        <p>SAASは、三次元、クロス表示、経年変化、を可視化しています。SAASを活用することにより、課題の解決に向けて有用な情報を適切に収集し、社会的事象を位置や空間的な広がりなどを考慮して地図上で捉える技能を高めることができます。<br/>
        </p>
        <br/> */}

{/* 
        <div>
          <div className={Styles.dispanel1}>
            <h3>テーマを探す方法</h3>
            <p>解説の文章。解説の文章。解説の文章。解説の文章。解説の文章。解説の文章。解説の文章。<br /></p>
          </div>
          <div>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/m1PbRqdfS3g" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <p>　</p>

        </div>
        <div>
          <div className={Styles.dispanel1}>
            <h3>市町村設定の方法</h3>
            <p>解説の文章。解説の文章。解説の文章。解説の文章。解説の文章。解説の文章。解説の文章。<br /></p>
          </div>
          <div>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/e9SIAcEz1yg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>          </div>

        </div>

        <p>　</p>
        <p>　</p> */}

        <div className={Styles.mokujititle}>
          <h1>地理的事象 -目次-</h1>
        </div>

{/*  */}
        <div className={Styles.mokuji} style={{display:"flex"}}>
          <div style={{width:"10%"}}>
            <div>
              <img className={Styles.mokujiimg} src="/chiriicon/06_人口_28px.svg" />
            </div>        
          </div>        
          <div className={Styles.mokujiright}>
            <div>
              <a href="#basic" className={Styles.mokujiname}><h2>人口</h2></a>
            </div>

            <div className={Styles.pagelinkdiv}>
                {data.allMarkdownRemark.edges.filter(x => x.node.frontmatter.tags[1] === 'さまざまな交通網の発達').map(({ node }, index) => (
                  <div className={Styles.pagelink} key={index}>
                    <Link  className={Styles.pagelink} to={"/"+node.frontmatter.no+"/erabu"}>{node.frontmatter.no}　{node.frontmatter.title}
                      </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>

{/*  */}
<div className={Styles.mokuji} style={{display:"flex"}}>
          <div style={{width:"10%"}}>
            <div>
              <img className={Styles.mokujiimg} src="/chiriicon/07_民族_28px.svg" />
            </div>        
          </div>        
          <div className={Styles.mokujiright}>
            <div>
              <a href="#basic" className={Styles.mokujiname}><h2>民族</h2></a>
            </div>

            <div className={Styles.pagelinkdiv}>
                {data.allMarkdownRemark.edges.filter(x => x.node.frontmatter.tags[1] === '観光のグローバル化').map(({ node }, index) => (
                  <div className={Styles.pagelink} key={index}>
                    <Link  className={Styles.pagelink} to={"/"+node.frontmatter.no+"/erabu"}>{node.frontmatter.no}　{node.frontmatter.title}
                      </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>

{/*  */}
<div className={Styles.mokuji} style={{display:"flex"}}>
          <div style={{width:"10%"}}>
            <div>
              <img className={Styles.mokujiimg} src="/chiriicon/06_人口_28px.svg" />
            </div>        
          </div>        
          <div className={Styles.mokujiright}>
            <div>
              <a href="#basic" className={Styles.mokujiname}><h2>国際機関</h2></a>
            </div>

            <div className={Styles.pagelinkdiv}>
                {data.allMarkdownRemark.edges.filter(x => x.node.frontmatter.tags[1] === '発展途上国と先進国の人口問題').map(({ node }, index) => (
                  <div className={Styles.pagelink} key={index}>
                    <Link  className={Styles.pagelink} to={"/"+node.frontmatter.no+"/erabu"}>{node.frontmatter.no}　{node.frontmatter.title}
                      </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>

{/*  */}
<div className={Styles.mokuji} style={{display:"flex"}}>
          <div style={{width:"10%"}}>
            <div>
              <img className={Styles.mokujiimg} src="/chiriicon/06_人口_28px.svg" />
            </div>        
          </div>        
          <div className={Styles.mokujiright}>
            <div>
              <a href="#basic" className={Styles.mokujiname}><h2>世界の国々</h2></a>
            </div>

            <div className={Styles.pagelinkdiv}>
                {data.allMarkdownRemark.edges.filter(x => x.node.frontmatter.tags[1] === '世界の都市の発展').map(({ node }, index) => (
                  <div className={Styles.pagelink} key={index}>
                    <Link  className={Styles.pagelink} to={"/"+node.frontmatter.no+"/erabu"}>{node.frontmatter.no}　{node.frontmatter.title}
                      </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>

{/*  */}
<div className={Styles.mokuji} style={{display:"flex"}}>
          <div style={{width:"10%"}}>
            <div>
              <img className={Styles.mokujiimg} src="/chiriicon/01_地形_28px.svg" />
            </div>        
          </div>        
          <div className={Styles.mokujiright}>
            <div>
              <a href="#basic" className={Styles.mokujiname}><h2>地形</h2></a>
            </div>

            <div className={Styles.pagelinkdiv}>
                {data.allMarkdownRemark.edges.filter(x => x.node.frontmatter.tags[1] === '発展途上国と先進国の都市').map(({ node }, index) => (
                  <div className={Styles.pagelink} key={index}>
                    <Link  className={Styles.pagelink} to={"/"+node.frontmatter.no+"/erabu"}>{node.frontmatter.no}　{node.frontmatter.title}
                      </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>

{/*  */}
<div className={Styles.mokuji} style={{display:"flex"}}>
          <div style={{width:"10%"}}>
            <div>
              <img className={Styles.mokujiimg} src="/chiriicon/02_気候_28px.svg" />
            </div>        
          </div>        
          <div className={Styles.mokujiright}>
            <div>
              <a href="#basic" className={Styles.mokujiname}><h2>気候</h2></a>
            </div>

            <div className={Styles.pagelinkdiv}>
                {data.allMarkdownRemark.edges.filter(x => x.node.frontmatter.tags[1] === '地理的な課題と地域調査').map(({ node }, index) => (
                  <div className={Styles.pagelink} key={index}>
                    <Link  className={Styles.pagelink} to={"/"+node.frontmatter.no+"/erabu"}>{node.frontmatter.no}　{node.frontmatter.title}
                      </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>

{/*  */}
<div className={Styles.mokuji} style={{display:"flex"}}>
          <div style={{width:"10%"}}>
            <div>
              <img className={Styles.mokujiimg} src="/chiriicon/02_地球環境問題_28px.svg" />
            </div>        
          </div>        
          <div className={Styles.mokujiright}>
            <div>
              <a href="#basic" className={Styles.mokujiname}><h2>地球環境問題</h2></a>
            </div>

            <div className={Styles.pagelinkdiv}>
                {data.allMarkdownRemark.edges.filter(x => x.node.frontmatter.tags[1] === 'さまざまな交通網の発達').map(({ node }, index) => (
                  <div className={Styles.pagelink} key={index}>
                    <Link  className={Styles.pagelink} to={"/"+node.frontmatter.no+"/erabu"}>{node.frontmatter.no}　{node.frontmatter.title}
                      </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>









      </div>
    </Layout3>
  );
};

export default HomePage;

interface IndexPageProps {
  data: {
    allMarkdownRemark: {
      edges: [
        {
          node: {
            frontmatter: {
              title: string;
              no: string;
              sdgs_no: string;
              sdgs_main: string;
              sdgs_subcat: string;
              kou_tisou_pt: string;
              kou_tisou_chp: string;
              kou_tisou_sec: string;
              kou_tisou_sbsec: string;
              kou_tisou_title: string;
              kou_titan_pt: string;
              kou_titan_chp: string;
              kou_titan_sec: string;
              kou_titan_sbsec: string;
              kou_titan_title: string;
              height_name: string;
              height_origin: string;
              height_origin_note: string;
              height_origin_url: string;
              height_origin_url_name: string;
              height_origin_url2: string;
              height_origin_url2_name: string;
              color_name: string;
              color_origin: string;
              color_origin_note: string;
              color_origin_url: string;
              color_origin_url2: string;
              color_origin_url_name: string;
              color_origin_url2_name: string;
              sokuchi: string;
              mesh_size: string;
              year_display: string;
              honbun: string;
              chyusyaku: string;
              hosoku: string;
              tags: string;
              kml_file: string;
              y202012: string;
              y202101: string;
              y202102: string;
              date: string;
              description: string;
            }
          }
        }
      ]
    }
  };
}

declare function graphql(x: TemplateStringsArray): any;
export const pageQuery = graphql`
query {
  allMarkdownRemark(sort: {fields: frontmatter___no}) {
    edges {
      node {
        frontmatter {
          title
          no
          sdgs_no
          sdgs_main
          sdgs_subcat
          kou_tisou_pt
          kou_tisou_chp
          kou_tisou_sec
          kou_tisou_sbsec
          kou_tisou_title
          kou_titan_pt
          kou_titan_chp
          kou_titan_sec
          kou_titan_sbsec
          kou_titan_title
          height_name
          height_origin
          height_origin_note
          height_origin_url_name
          height_origin_url2_name
          height_origin_url
          height_origin_url2
          color_name
          color_origin
          color_origin_note
          color_origin_url_name
          color_origin_url2_name
          color_origin_url
          color_origin_url2
          sokuchi
          mesh_size
          year_display
          honbun
          chyusyaku
          hosoku
          tags
          kml_file
          y202012
          y202101
          y202102
          date
          description
          hanrei1_name
          hanrei1_color
          hanrei2_name
          hanrei2_color
          hanrei3_name
          hanrei3_color
          hanrei4_name
          hanrei4_color
          height_ratio
        }
      }
    }
  }
}

`;
